import React, { ReactElement } from 'react';
import { Button } from '@brandwatch/axiom-components';
import { Card } from './card';

const PasswordlessCard = (props: { onClick: () => void; }): ReactElement => {
	const { onClick } = props;

	return (
		<Card title={'Explore the dashboard that has been shared with you'}>
			<div className='main-button'>
				<Button color="accent"
					full="true"
					onClick={onClick}
					shape="rectangle"
					size="large"
					variant="primary"
				>
					Open Dashboard
				</Button>
			</div>
		</Card >
	);
};

export { PasswordlessCard };

