import React from 'react';
import { ErrorCard } from '../components/errorCard';
import '../App.css';

const Error = () =>
	<div className='card-container'>
		<ErrorCard />
	</div>;

export { Error };
