import React, { ReactElement } from 'react';
import { PasswordlessCard } from '../components/passwordlessCard';
import '../App.css';

const Passwordless = (props: { onSubmit: () => void; }): ReactElement => {
	const { onSubmit } = props;

	return (
		<div className='card-container'>
			<PasswordlessCard onClick={onSubmit} />
		</div>
	);
};

export { Passwordless };
