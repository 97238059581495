import React, { ReactElement } from 'react';
import { Card } from './card';

const ExpiredCard = (): ReactElement =>
	<Card title="This link has expired">
		<div className='main-description--long'>
			Please ask your contact to send a new link.
		</div>
	</Card >;

export { ExpiredCard };
