import React, { ReactElement } from 'react';
import './logo.css';

const Logo = (): ReactElement =>
	<svg className='bw-logo' viewBox="20 20 60 55" aria-hidden="true">
		<g>
			<path className="logo-blue"
				d="M21.739,35.234V51.8l19.289,9.133L60.754,40.1,47.82,16.446a6.823,6.823,0,0,0-1.213.548L25.132,29.369A6.769,6.769,0,0,0,21.739,35.234Z"
			/>
			<polygon className="logo-orange"
				points="41.028 60.935 64.285 71.947 74.84 65.865 60.754 40.101 41.028 60.935"
			/>
			<path className="logo-purple"
				d="M72.31,27.9l-18.917-10.9a6.8,6.8,0,0,0-5.573-.548L60.754,40.1Z"
			/>
			<path className="logo-red"
				d="M74.84,65.865l.028-.017a6.767,6.767,0,0,0,3.393-5.865V35.234a6.769,6.769,0,0,0-3.393-5.865L72.31,27.9,60.754,40.1Z"
			/>
			<path
				className="logo-green"
				d="M21.739,51.8v8.181a6.767,6.767,0,0,0,3.393,5.865l7.275,4.192,8.621-9.105Z"
			/>
			<path className="logo-yellow"
				d="M32.407,70.04l14.2,8.183a6.8,6.8,0,0,0,6.786,0l10.892-6.276L41.028,60.935Z"
			/>
		</g>
	</svg>;

export { Logo };
