import React, { ReactElement } from 'react';
import DashboardsEngine from '@brandwatch/dashboards-engine';
import { IDashboard } from '@brandwatch/dashboards-engine/src/decs';
import { bcrToDETransform, IBCRDashboardConfiguration } from '@brandwatch/dashboards-engine/dist/helpers/transform';
import '../App.css';

const Dashboard = (props: { dashboardJSON: IDashboard; }): ReactElement => {
	const { dashboardJSON } = props;

	const options = {
		'footer': {
			'show': true
		},
		'header': {
			'fixed': true,
			'show': true
		}
	};

	const dashboardConfiguration = { dashboard: dashboardJSON } as unknown as IBCRDashboardConfiguration;
	const transformedDashboardJSON: IDashboard = bcrToDETransform(dashboardConfiguration);

	return (
		<DashboardsEngine
			dashboard={transformedDashboardJSON}
			options={options}
		/>
	);
};

export { Dashboard };
