import React, { ReactElement, ReactNode } from 'react';
import { Logo } from './logo';
import './card.css';

const Card = (props: { children?: ReactNode; title?: string; }): ReactElement => {
	const { children, title } = props;

	return (
		<main className='card'>
			<section className='body'>
				<div className='logo' role="img" aria-label="Brandwatch logo">
					<Logo />
				</div>
				<h1 className='title'>{title}</h1>
				{children}
			</section>
			<div className='delimiter' aria-hidden="true" />
			<div className='footer' role="complementary">
				<div className='title'>
					<p>What is Brandwatch?</p>
				</div>
				<p className='content'>Our products bring structure and meaning to the voices of billions of people, to provide you with real-time insights and reporting capabilities, so that you can make decisions that truly fit with consumer and buyer needs.</p>
			</div>
		</main>
	);
};

export { Card };
