import React, { ReactElement } from 'react';
import { Card } from './card';

const ErrorCard = (): ReactElement =>
	<Card title="Sorry, we could not load this dashboard.">
		<div className='main-description--long'>
			Please try refreshing this page. If the issue continues, contact the person who sent you this link.
		</div>
	</Card >;

export { ErrorCard };

