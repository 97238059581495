import React, { ReactElement } from 'react';
import { ExpiredCard } from '../components/expiredCard';
import '../App.css';

const Expired = (): ReactElement =>
	<div className='card-container'>
		<ExpiredCard />
	</div>;

export { Expired };
