import React, { FormEvent, ReactElement, useState } from 'react';
import {
	Button,
	UsageHint,
	TextInput,
	TextInputIcon,
	TooltipContent,
	TooltipContext
} from '@brandwatch/axiom-components';
import { Card } from './card';

const PasswordCard = (props: { hasIncorrectPassword: boolean; onSubmit: (password: string) => void; }): ReactElement => {
	const [isPreviewIconVisible, setIsPreviewIconVisible] = useState<boolean>(false);
	const [isPasswordConcealed, setIsPasswordConcealed] = useState<boolean>(false);
	const [hasPasswordChanged, setHasPasswordChanged] = useState<boolean>(false);
	const [password, setPassword] = React.useState('');

	const {hasIncorrectPassword, onSubmit} = props;

	const wrongPasswordTooltip = (
		<TooltipContext className="password-input-tooltip" color="white">
			<TooltipContent>
				This password was not recognised. Please try again, or contact the person who sent you this link.
			</TooltipContent>
		</TooltipContext>
	);

	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		onSubmit(password);
		setHasPasswordChanged(false);
	};

	const handleKeyDown = (e: KeyboardEvent): void => {
		const inputField = e.target as HTMLInputElement;
		const shouldShowPreviewIcon = inputField.value.length > 0;

		setIsPreviewIconVisible(shouldShowPreviewIcon);
		setHasPasswordChanged(true);
	};

	return (
		<Card title={'Explore the dashboard that has been shared with you'}>
			<form onSubmit={handleSubmit}>
				<div className="password-input">
					<div className="password-header">
						<div>Password</div>
						<UsageHint iconName={'information-circle'} position="right">
							Need the password? Please contact the person who shared this link with you.
						</UsageHint>
					</div>
					<TextInput invalid={hasIncorrectPassword && !hasPasswordChanged}
						onChange={(e) => setPassword(e.target.value)}
						onKeyDown={handleKeyDown}
						placeholder="Enter password"
						space={'x2'}
						type={isPasswordConcealed ? 'text' : 'password'}
					>
						{
							hasIncorrectPassword && !hasPasswordChanged &&
							<TextInputIcon align="right"
								children=""
								iconColor="error"
								name="warning-circle"
								tooltip={wrongPasswordTooltip}
							/>
						}
						{
							isPreviewIconVisible &&
							<TextInputIcon align="right"
								children=""
								name={isPasswordConcealed ? 'mask' : 'preview'}
								onClick={() => setIsPasswordConcealed(!isPasswordConcealed)}
								tooltip={`${isPasswordConcealed ? 'Conceal' : 'Reveal'} the password`}
							/>
						}
					</TextInput>
				</div>
				<div className="main-button">
					<Button color="accent"
						disabled={password.length < 8}
						full="true"
						shape="rectangle"
						size="large"
						type="submit"
						variant="primary"
					>
						Open Dashboard
					</Button>
				</div>
			</form>
		</Card>
	);
};

export { PasswordCard };
