type TError = Error & {
    response: object;
};

const fetchSharedResource = (resourceId: string, password: string) => {
	const fullUrl = `${process.env.REACT_APP_SHARED_RESOURCE_URL}/shares/${resourceId}/resource`;
	const auth = `basic ${btoa(`:${password}`)}`;

	return fetch(fullUrl, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			...(password !== '' ? {
				Authorization: auth
			} : {})
		},
	})
		.then(checkStatus)
		.then((response) => response.json());
};

const checkStatus = (response: Response): Response => {
	if (response && response.status >= 200 && response.status < 300) {
		return response;
	}

	const error = new Error(response.statusText) as TError;
	error.response = response;
	throw error;
};

export { fetchSharedResource };
