import React, { ReactElement } from 'react';
import './loader.css';

const Loader = (): ReactElement => 
	<div id="loadSpinner">
		<svg className="spinner"
			viewBox="0 0 28 28"
			xmlns="http://www.w3.org/2000/svg"
		>
			<defs>
				<clipPath id="load-spinner_mask--outer">
					<path d="M18.896 24.81l-4.837 2.787a3.019 3.019 0 01-2.896.064l-.118-.064-9.538-5.496A3.007 3.007 0 01.003 19.62L0 19.496V8.504C0 7.47.531 6.513 1.4 5.963l.107-.064L11.045.403c.13-.075.264-.14.4-.193l.139-.05.136-.043a3.02 3.02 0 012.21.216l.129.07 9.538 5.496A3.007 3.007 0 0125.1 8.38l.002.124v10.992a3.007 3.007 0 01-1.4 2.54l-.12.072-9.524 5.489 4.837-2.788z"
						fillOpacity="0"
					/>
				</clipPath>
				<clipPath id="load-spinner_mask-orange">
					<rect height="28"
						transform="rotate(-46.3) translate(-14, 20)"
						width="28"
						x="0"
						y="0"
					/>
				</clipPath>
				<clipPath id="load-spinner_mask-yellow">
					<rect height="28"
						transform="rotate(25.5) translate(6.9, 14.2)"
						width="21"
						x="0"
						y="0"
					/>
				</clipPath>
				<clipPath id="load-spinner_mask-red">
					<rect height="10"
						transform="rotate(61) translate(5, -20)"
						width="28"
						x="0"
						y="0"
					/>
				</clipPath>
				<clipPath id="load-spinner_mask-blue">
					<rect height="20"
						transform="rotate(-46.3) translate(-24, 0)"
						width="60"
						x="0"
						y="0"
					/>
				</clipPath>
			</defs>
			<g className="logo" clipPath="url(#load-spinner_mask--outer)">
				<rect fill="rgb(217, 217, 218)" x="0" y="0" width="28" height="28" />
				<g className="phase-1">
					<g transform="rotate(-46.3) translate(-14, 20)">
						<rect className="tiles tile-orange"
							fill="rgb(249,145,50)"
							height="12.5"
							width="26.2"
							x="0"
							y="0"
						/>
					</g>
					<g clipPath="url(#load-spinner_mask-orange)">
						<g transform="rotate(25.5) translate(6.75, 14.2)">
							<rect className="tiles tile-yellow"
								fill="rgb(255,190,10)"
								height="6"
								width="21.1"
								x="0"
								y="0"
							/>
						</g>
					</g>
					<g clipPath="url(#load-spinner_mask-blue)">
						<g transform="rotate(25.5) translate(6.75, 14.2)">
							<rect className="tiles tile-yellow"
								fill="rgb(252,213,18)"
								height="6"
								width="21.1"
								x="0"
								y="0"
							/>
						</g>
					</g>
					<g clipPath="url(#load-spinner_mask-orange)">
						<g transform="rotate(61) translate(5.7, -20)">
							<rect className="tiles tile-red"
								fill="rgb(255,109,86)"
								height="10"
								width="25.15"
								x="0"
								y="0"
							/>
						</g>
					</g>
					<g clipPath="url(#load-spinner_mask-blue)">
						<g transform="rotate(61) translate(5.7, -20)">
							<rect className="tiles tile-red"
								fill="rgb(255,152,183)"
								width="25.15"
								height="10"
								x="0"
								y="0"
							/>
						</g>
					</g>
					<g transform="rotate(-46.3) translate(-15, 5)">
						<rect className="tiles tile-blue"
							fill="rgb(87,183,221)"
							height="15"
							width="26.8"
							x="0"
							y="0"
						/>
					</g>
					<g clipPath="url(#load-spinner_mask-red)">
						<g transform="rotate(-46.3) translate(-15, 5)">
							<rect className="tiles tile-blue"
								fill="rgb(169,139,188)"
								height="15"
								width="26.8"
								x="0"
								y="0"
							/>
						</g>
					</g>
					<g clipPath="url(#load-spinner_mask-yellow)">
						<g transform="rotate(-46.3) translate(-15, 5)">
							<rect className="tiles tile-blue"
								fill="rgb(138,197,57)"
								height="15"
								width="26.8"
								x="0"
								y="0"
							/>
						</g>
					</g>
				</g>
				<g className="phase-2">
					<g transform="rotate(-46.3) translate(-15, 5)">
						<rect className="tiles tile-blue"
							fill="rgb(87,183,221)"
							height="15"
							width="26.8"
							x="0"
							y="0"
						/>
					</g>
					<g clipPath="url(#load-spinner_mask-orange)">
						<g transform="rotate(61) translate(5.7, -20)">
							<rect className="tiles tile-red"
								fill="rgb(232, 148, 175	)"
								height="10"
								width="25.15"
								x="0"
								y="0"
							/>
						</g>
					</g>
					<g clipPath="url(#load-spinner_mask-blue)">
						<g transform="rotate(61) translate(5.7, -20)">
							<rect className="tiles tile-red"
								fill="rgb(169,139,188)"
								height="10"
								width="25.15"
								x="0"
								y="0"
							/>
						</g>
					</g>
					<g clipPath="url(#load-spinner_mask-orange)">
						<g transform="rotate(25.5) translate(6.75, 14.2)">
							<rect className="tiles tile-yellow"
								fill="rgb(246, 217, 74)"
								height="6"
								width="21.1"
								x="0"
								y="0"
							/>
						</g>
					</g>
					<g clipPath="url(#load-spinner_mask-blue)">
						<g transform="rotate(25.5) translate(6.75, 14.2)">
							<rect className="tiles tile-yellow"
								fill="rgb(138,197,57)"
								height="6"
								width="21.1"
								x="0"
								y="0"
							/>
						</g>
					</g>
					<g transform="rotate(-46.3) translate(-14, 20)">
						<rect className="tiles tile-orange"
							fill="rgb(249,145,50)"
							height="12.5"
							width="26.2"
							x="0"
							y="0"
						/>
					</g>
					<g clipPath="url(#load-spinner_mask-yellow)">
						<g transform="rotate(-46.3) translate(-14, 20)">
							<rect className="tiles tile-orange"
								fill="rgb(255,190,10)"
								x="0"
								y="0"
								width="26.2"
								height="12.5"
							/>
						</g>
					</g>
					<g clipPath="url(#load-spinner_mask-red)">
						<g transform="rotate(-46.3) translate(-14, 20)">
							<rect className="tiles tile-orange"
								fill="rgb(255,109,86)"
								height="12.5"
								width="26.2"
								x="0"
								y="0"
							/>
						</g>
					</g>
				</g>
			</g>
		</svg>
	</div>;

export { Loader };
