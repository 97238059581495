import React, { ReactElement } from 'react';
import { PasswordCard } from '../components/passwordCard';
import '../App.css';

const Password = (props: { hasIncorrectPassword: boolean; onSubmit: (password: string) => void; }): ReactElement => {
	const { hasIncorrectPassword, onSubmit } = props;

	return (
		<div className='card-container'>
			<PasswordCard hasIncorrectPassword={hasIncorrectPassword} onSubmit={onSubmit} />
		</div>
	);
};

export { Password };
