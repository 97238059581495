type TProperty = string | number | Date | (string | number | Date)[];
type TProperties = Record<string, TProperty>;
type TWindowWithFrontendModules = Window & typeof globalThis & {
	importFrontendModule: (moduleName: string) => TFrontendModule;
};
type TFrontendModule = {
	track?: (eventName: string, props: TProperties) => void;
};

export const track = async (eventName: string, props?: TProperties) => {
	const userTrackingSDK: TFrontendModule = await (window as TWindowWithFrontendModules)?.importFrontendModule('sdk-user-tracking');

	if (userTrackingSDK) {
		userTrackingSDK?.track?.(eventName, {
			...props,
			external: 'true'
		} || {});
	}
};

export const eventNames = {
	DASHBOARD_INCORRECT_PASSWORD: 'research - shared static dashboard incorrect password submitted',
	DASHBOARD_OPENED: 'research - shared static dashboard opened'
};
